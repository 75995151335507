<template>
  <div class="circle-progress__wrapper">
    <div class="circle-progress__center">
      <div>
        <img src="@/assets/images/i5/logo.png" class="circle-progress__logo" />
        <div class="circle-progress__percent">{{ `${percent}%` }}</div>
      </div>
    </div>
    <svg
      width="126"
      height="126"
      viewPort="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="60"
        cx="63"
        cy="63"
        fill="transparent"
        stroke-width="6px"
        stroke="#F5F5F5"
        stroke-dasharray="565.48"
        stroke-dashoffset="0"
      ></circle>
      <circle
        :r="r"
        cx="63"
        cy="63"
        class="circle-progress__bar"
        stroke-linecap="round"
        fill="transparent"
        stroke="#B92A21"
        stroke-dasharray="565.48"
        stroke-width="6px"
        :stroke-dashoffset="strokeDashoffset"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "circleProgress",
  data() {
    return {
      r: 60,
      strokeDashoffset: 0,
    };
  },
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "default", // small
    },
  },
  watch: {
    percent: {
      immediate: true,
      handler(val) {
        this.setPercent(val);
      },
    },
  },
  methods: {
    setPercent(val) {
      const r = this.r;
      const c = Math.PI * (r * 2);
      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }
      const pct = ((100 - val) / 100) * c;
      this.strokeDashoffset = pct + 190;
    },
  },
  created() {
    this.setPercent(this.percent);
  },
  components: {},
};
</script>
